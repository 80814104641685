import React from "react"
import Header from "../components/Header.js"
import Footer from "../components/Footer.js"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"

import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

import Wave from "react-wavify"

import SEO from "../components/seo"

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
}

class Typer extends React.Component {
  static defaultProps = {
    heading: "",
    dataText: [],
  }

  constructor(props) {
    super(props)

    this.state = {
      text: "",
      isDeleting: false,
      loopNum: 0,
      typingSpeed: 500,
    }
  }

  componentDidMount() {
    this.handleType()
  }

  handleType = () => {
    const { dataText } = this.props
    const { isDeleting, loopNum, text, typingSpeed } = this.state
    const i = loopNum % dataText.length
    const fullText = dataText[i]

    this.setState({
      text: isDeleting
        ? fullText.substring(0, text.length - 1)
        : fullText.substring(0, text.length + 1),
      typingSpeed: isDeleting ? 50 : 500,
    })

    if (!isDeleting && text === fullText) {
      setTimeout(() => this.setState({ isDeleting: true }), 1000)
    } else if (isDeleting && text === "") {
      this.setState({
        isDeleting: false,
        loopNum: loopNum + 1,
      })
    }

    setTimeout(this.handleType, typingSpeed)
  }

  render() {
    return (
      <h2 className="me-h2-left">
        {this.props.heading}&nbsp;
        <span>{this.state.text}</span>
        <span className="me-typer-cursor" />
      </h2>
    )
  }
}

function Why() {
  return (
    <>
      <SEO title="Por que a grafiame? | Grafiame" />
      <Header />
      <main>
        {/* <div className="me-x-flex me-flex-wrap me-content-container me-margin-tb-32"> */}
        <Box px={5} py={10}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item md={7} sm={12}>
              <h2 className="me-h2-left">Por que a Grafiame?</h2>
              <p>
                Nossa missão nesse mundo, mundo vasto mundo (mais vasto é meu
                coração), é poder revelar o verdadeiro potencial da Educação por
                meio da tecnologia, escrita e ciência. Nós somos apaixonados
                pelo que fazemos e sabemos a preciosidade do tempo para você,
                para seus filhos ou filhas, pais ou mães, professoras ou
                professores, colaboradores e colaboradoras. Por isso, no nosso
                universo, nós nos desafiamos a ir sempre além do que uma empresa
                convencional de Educação se propõe a fazer. Dessa forma,
                garantimos que a Grafiame seja sempre um espaço de
                ensino/aprendizagem inovador feito especialmente para você.
              </p>
            </Grid>
            {/* </div> */}
            {/* <div className="me-flex-1 item img"> */}
            <Grid
              item
              container
              md={5}
              sm={12}
              justify="center"
              alignItems="center"
            >
              <img
                className="me-img-content me-margin-32"
                src="/img/illustrations/tellmewhy.svg"
                alt="Um velhinho pensando na Grafiame"
              ></img>
            </Grid>
            {/* </div> */}
          </Grid>
        </Box>
        {/* </div> */}
        <Wave
          fill="#06d6a0"
          paused={false}
          options={{
            height: 4,
            amplitude: 45,
            speed: 0.08,
            points: 4,
          }}
        />
        {/* <div className="me-content-container me-bg-verzul"> */}
        <Box className="me-bg-verzul">
          {/* <div className="me-x-flex me-flex-wrap-reverse me-content-container me-margin-tb-32"> */}
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid
              item
              container
              md={5}
              sm={12}
              justify="center"
              alignItems="center"
            >
              {/* <div className="me-flex-1 item img"> */}
              <img
                alt="Um grupo de pessoas: um velinho, uma menina com cabelo verde e uma menina em sua cadeira de rodas"
                className="me-img-content me-margin-32"
                src="./img/illustrations/thegroup.svg"
              ></img>
            </Grid>
            {/* </div> */}
            {/* <div className="me-flex-1 me-text-content item"> */}
            <Grid item md={7} sm={12}>
              <Typer
                heading={"Feito para"}
                dataText={["todos", "todas", "tod@s"]}
              />
              <p>
                Queremos que nossas soluções atinjam o maior número possível de
                pessoas, para que possamos ajudar a todos da melhor forma. Para
                isso, analisamos cada caso de forma a diagnosticar os caminhos
                ideais para o processo de aprendizagem, por isso idealizamos a
                inclusão de todos por meio da nossa acessibilidade. Temos todo
                cuidado em relação ao perfil de cada cliente.
              </p>
              {/* </div> */}
            </Grid>
          </Grid>
          <div className="me-margin-t-48">
            <h2 className="me-h2-center">
              Feito para você<span className="me-the-end">.</span>
            </h2>
            {/* <div className="me-x-flex"> */}
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <div className="me-box-full">
                <h4 className="me-h4">
                  Você Escola<span className="me-the-end">.</span>
                </h4>
                <p>
                  A redação é a “pupila dos olhos” do conhecimento do(a)
                  aluno(a), isso é fato. Quem sabe escrever bem, registra e
                  transfere o que aprendeu de forma clara e eficiente, pois
                  somos o que comunicamos. Por ser morosa e complexa a
                  aprendizagem da redação, a Grafiame entende o perfil dos
                  alunos da sua escola e, por isso, apresenta métodos e soluções
                  voltados a eles com uma boa equipe de professores, correções
                  externas e claras, cujos relatórios ensinem e não os confunda,
                  notas com margem mínima de discrepância com corretores dos
                  maiores vestibulares do Brasil, estrutura tecnológica de
                  atendimento ao aluno, boas propostas, boas leituras, etc. Nas
                  escolas, implantamos o Núcleo de Redação com toda essa
                  estrutura. Com isso, os ruídos de que o ensino de redação não
                  atinge os objetivos de toda a comunidade escolar pode
                  minimizar muito. Existimos para lhe ajudar.
                </p>
              </div>
              <div className="me-box-full">
                <h4 className="me-h4">
                  Você Empresa<span className="me-the-end">.</span>
                </h4>
                <p>
                  Toda empresa é construída e alicerçada por meio da
                  comunicação, sabemos disso. A Grafiame, no ambiente
                  corporativo, busca soluções para diminuir problemas de
                  comunicação que promovem uma série de situações complexas
                  tanto na produtividade quanto nas relações interpessoais.
                  Quantas vezes há retrabalho por falta de entendimento? Quantas
                  vezes um e-mail sem clareza gera a maior confusão? E agora que
                  a escrita ficou mais evidente nesse contexto incerto? Somos a
                  solução.
                </p>
              </div>
              <div className="me-box-full">
                <h4 className="me-h4">
                  Você Aluno(a)<span className="me-the-end">.</span>
                </h4>
                <p>
                  Você sabe a importância de se estudar redação? Você entende as
                  notas que lhe são atribuídas? E os relatórios de linguagem são
                  compreendidos? Com a Grafiame, você descobre um lugar
                  diferente para estudar. Sério mesmo. Temos um ambiente
                  totalmente voltado para sua aprendizagem, pois buscamos sempre
                  um trabalho colaborativo, lúdico, em que a criatividade, a
                  afetividade, a empatia e a determinação norteiam todos os
                  nossos serviços para você se sentir acolhido em cada momento
                  de estudo. Faça parte desse universo apaixonado por
                  conhecimento.
                </p>
              </div>
            </Grid>
          </div>
          {/* </div> */}
          {/* </div> */}
        </Box>
        <Wave
          className="me-inverse"
          fill="#06d6a0"
          paused={false}
          options={{
            height: 4,
            amplitude: 45,
            speed: 0.08,
            points: 4,
          }}
        />
        <div className="me-margin-tb-32">
          <h2 className="me-h2-center">
            Um pouquinho do que fazemos<span className="me-the-end">.</span>
          </h2>
          <Carousel
            className="me-carousel"
            responsive={responsive}
            showDots={true}
            autoPlaySpeed={10}
            infinite={true}
            keyBoardControl={true}
            dotListClass="dots"
            itemClass="item"
          >
            <div>
              <div className="me-block"></div>
              <img
                src="../img/plataforma_1.jpg"
                alt="Demonstração do painel do aluno que mostra suas últimas redações e os seus recados"
              ></img>
            </div>

            <img
              src="../img/plataforma_2.jpg"
              alt="Demonstração das métricas de um aluno"
            ></img>
            <img
              src="../img/plataforma_3.jpg"
              alt="Demonstração de um card de uma redação pendente"
            ></img>
          </Carousel>
        </div>
      </main>
      <Footer />
    </>
  )
}

export default Why
